import React from "react";
import PropTypes from "prop-types";
import { Link, graphql, StaticQuery } from "gatsby";

class EducatorCategoryList extends React.Component {
  render() {
    const { data, category } = this.props;
    const { edges: posts } = data.allMarkdownRemark;

    const categoryPosts = posts.filter(
      (post) =>
        !!post.node.frontmatter.category &&
        post.node.frontmatter.category === category
    );

    if (categoryPosts.length < 1) {
      return (
        <div>
          This category currently doesn't have any resources, check back soon!
        </div>
      );
    }

    return (
      <div className="columns is-multiline is-mobile">
        {categoryPosts &&
          categoryPosts.map(({ node: post }) => (
            <div className="is-parent column is-12 " key={post.id}>
              <article
                className={`blog-list-item tile is-12 is-child box notification`}
              >
                <header>
                  <p className="post-meta">
                    <Link
                      className="title has-text-primary is-size-4"
                      to={post.fields.slug}
                    >
                      {post.frontmatter.title}
                    </Link>
                    <br />
                    {/*<span className="subtitle is-size-5 is-block">*/}
                    {/*    {post.frontmatter.datePosted}*/}
                    {/*</span>*/}
                  </p>
                </header>
                <p>{post.frontmatter.description}</p>
              </article>
            </div>
          ))}
      </div>
    );
  }
}

EducatorCategoryList.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default (props) => {
  return (
    <StaticQuery
      query={graphql`
        query EducatorCategoryListQuery {
          allMarkdownRemark(
            sort: { order: DESC, fields: [frontmatter___datePosted] }
            filter: {
              frontmatter: { templateKey: { eq: "educator-resource" } }
            }
          ) {
            edges {
              node {
                id
                fields {
                  slug
                }
                frontmatter {
                  title
                  description
                  category
                  templateKey
                  datePosted(formatString: "MMMM DD, YYYY")
                }
              }
            }
          }
        }
      `}
      render={(data, count) => (
        <EducatorCategoryList data={data} count={count} {...props} />
      )}
    />
  );
};
