import Layout from "../../components/Layout";
import React from "react";
import EducatorCategoryList from "../../components/EducatorCategoryList";
import { Helmet } from "react-helmet";

export default () => {
  const title = "Planning Category | Educator Resources";
  const description =
    "Plan your routines and plan your time using our calendars and resources. Help yourself get organized here!";
  return (
    <Layout>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
      </Helmet>
      <section className="section">
        <div className="container">
          <div className="content">
            <h1 className="title">Planning Resources</h1>
            <p>{description}</p>
            <EducatorCategoryList category="planning-resources" />
          </div>
        </div>
      </section>
    </Layout>
  );
};
